import { Box, Grid, createStyles, makeStyles } from '@material-ui/core';
import Pet from '@spike/pet-model';
import { FunctionComponent } from 'react';

import { faCalendarAlt, faCat, faDog, faMars, faNarwhal, faRuler } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import { PetPersonality } from 'components/UI';
import { ItemLabelIcon } from 'components/UI/ItemLabelIcon';
import { getAge } from 'utils/DateUtils';

interface PetInformationResumeProps {
    pet: Pet;
    extended?: boolean;
    className?: string;
}
const useStyles = makeStyles(() =>
    createStyles({
        label: {
            fontSize: 16,
            fontWeight: 400
        }
    })
);

export const PetInformationResume: FunctionComponent<PetInformationResumeProps> = props => {
    const classes = useStyles();
    return (
        <Box className={clsx(props.className)}>
            <Grid container>
                <Grid
                    item
                    xs={6}
                >
                    <ItemLabelIcon
                        label={props.pet?.breed?.name ? props.pet?.breed?.name : 'Not defined'}
                        icon={
                            props.pet?.type?.singular === 'Cat'
                                ? faCat
                                : props.pet?.type?.singular === 'Dog'
                                ? faDog
                                : faNarwhal
                        }
                        classNameLabel={classes.label}
                    />
                    <ItemLabelIcon
                        classNameLabel={classes.label}
                        label={
                            props.pet.birthdate
                                ? `${getAge(props.pet.birthdate).toString()} years`
                                : props.pet.age
                                ? props.pet.age.name.toLowerCase()
                                : 'Not defined'
                        }
                        icon={faCalendarAlt}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <ItemLabelIcon
                        label={props.pet?.gender?.name ? props.pet.gender.name : 'Not defined'}
                        icon={faMars}
                        classNameLabel={classes.label}
                    />
                    {props.extended && <PetPersonality pet={props.pet} />}
                    <ItemLabelIcon
                        classNameLabel={classes.label}
                        label={
                            props.pet?.weight
                                ? `${Math.trunc(props.pet.weight)} Lbs ` +
                                  (props.pet?.size?.name ? `(${props.pet?.size?.name})` : '(Not defined)')
                                : props.pet?.size?.name
                                ? props.pet?.size?.name
                                : 'Not defined'
                        }
                        icon={faRuler}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default PetInformationResume;
