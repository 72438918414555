import React, { FunctionComponent, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Client from '@spike/client-model';
import { Chip } from 'components/UI';
import clsx from 'clsx';
import { faLocationDot, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import ItemLabelIcon from 'components/UI/ItemLabelIcon';
import { faPhoneFlip } from '@fortawesome/pro-regular-svg-icons';
import { formatPhoneNumber } from '@spike/phone-utils';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientBookingsThunk } from '@spike/bookings-action';
import moment from 'moment';
import { convertToYMDWithoutTimeZone } from '@spike/date-utils';
import { RootState } from 'store';
import Tag from 'components/UI/V2/Tag/Tag';

interface Props {
    client?: Client;
    deleted?: boolean;
    onShowClient: (clientId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            border: '1px solid #D4D4D4',
            borderRadius: 8,
            padding: 15,
            paddingLeft: 22,
            paddingBottom: 12,
            width: '100%'
        },
        clientInfoContainer: {
            width: '100%'
        },
        title: {
            'fontWeight': 600,
            'fontSize': 16,
            'lineHeight': '16px',
            'paddingBottom': 6,
            'cursor': 'pointer',
            [theme.breakpoints.up('md')]: {
                paddingBottom: 8
            },
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 10
            },

            '&:hover': {
                textDecoration: 'underline'
            }
        },
        text: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: 14,
            lineHeight: '17px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 5
            }
        },
        tagsContainer: {
            gap: 6,
            display: 'flex',
            alignItems: 'flex-start',
            paddingTop: 8,
            flexWrap: 'wrap',
            width: '100%'
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        tag: {
            'background': '#BCB8AE',
            'color': '#fff',
            'height': 22,
            '& .MuiChip-label': {
                fontSize: '13px !important'
            },
            [theme.breakpoints.up('md')]: {
                height: 24
            }
        },
        tagDeleted: {
            'background': '#EF4F57',
            'color': '#fff',
            'height': 24,
            '& .MuiChip-label': {
                fontSize: '13px !important',
                fontWeight: 400
            },
            [theme.breakpoints.up('md')]: {
                height: 24
            }
        }
    })
);

export const ClientInfo: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const marketplace = useMarketplace();
    const apiClientWrapper = useApiClientWrapper();

    const bookingsCount = useSelector<RootState, number | undefined>(
        state => state.bookings.clientBookings.totalBookingsCount
    );

    useEffect(() => {
        if (props.client?.id) {
            const from = convertToYMDWithoutTimeZone(moment().tz(marketplace.schedule.timeZone));
            const to = convertToYMDWithoutTimeZone(moment().tz(marketplace.schedule.timeZone));

            dispatch(
                fetchClientBookingsThunk(
                    apiClientWrapper,
                    from,
                    to,
                    props.client?.id,
                    props.client?.pets.map(pet => pet.id)
                )
            );
        }
    }, [props.client?.id]);

    return (
        <Box>
            {!props.client ? (
                <CircularProgress
                    size="1.5rem"
                    style={{ color: '#EAB464' }}
                />
            ) : (
                <Box className={classes.container}>
                    <Box className={classes.clientInfoContainer}>
                        <Box className={clsx(classes.infoContainer)}>
                            <Typography
                                className={classes.title}
                                onClick={() => props.onShowClient(props.client?.id!)}
                            >
                                {props.client.firstName} {props.client.lastName}
                            </Typography>

                            {props.client.phone && (
                                <ItemLabelIcon
                                    label={
                                        <Box className={classes.text}>
                                            {formatPhoneNumber(
                                                props.client.phone,
                                                String(marketplace.basics.address.country?.id)
                                            )}
                                        </Box>
                                    }
                                    icon={faPhoneFlip}
                                />
                            )}

                            {props.client.email && (
                                <ItemLabelIcon
                                    label={<Box className={classes.text}>{props.client.email}</Box>}
                                    icon={faEnvelope}
                                />
                            )}

                            {props.client?.address && (
                                <ItemLabelIcon
                                    label={
                                        <Box className={classes.text}>
                                            {`${props.client?.address?.street}, ${props.client?.address?.city}, ${props.client?.address?.state}`}
                                        </Box>
                                    }
                                    icon={faLocationDot}
                                />
                            )}
                        </Box>
                        {!props.deleted &&
                            (props.client.tags?.positive?.length > 0 ||
                                props.client.tags?.negative?.length > 0 ||
                                bookingsCount === 1 ||
                                props.client.blocked) && (
                                <Box className={classes.tagsContainer}>
                                    {props.client.blocked && (
                                        <Tag
                                            size="small"
                                            variant="danger"
                                        >
                                            Blocked
                                        </Tag>
                                    )}

                                    {bookingsCount === 1 && (
                                        <Chip
                                            key={'new-client'}
                                            label={'New Client'}
                                            className={classes.tag}
                                        />
                                    )}

                                    {props.client.tags?.positive.map(tag => {
                                        return (
                                            <Chip
                                                key={tag.id}
                                                label={tag.name}
                                                className={classes.tag}
                                            />
                                        );
                                    })}
                                    {props.client.tags?.negative.map(tag => {
                                        return (
                                            <Chip
                                                key={tag.id}
                                                label={tag.name}
                                                className={classes.tag}
                                            />
                                        );
                                    })}
                                </Box>
                            )}
                        {props.deleted && (
                            <Box className={classes.tagsContainer}>
                                <Chip
                                    label="Client Deleted"
                                    className={classes.tagDeleted}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ClientInfo;
